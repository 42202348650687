import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'

// Parse YouTube ID from URL
function parseId(url: string) {
  if (!url || !url.trim()) {
    return
  }

  const regex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  return url.match(regex) ? RegExp.$2 : url
}

const IFrame = ({ hidden, ...props }: React.HTMLProps<HTMLIFrameElement> & { hidden?: boolean }) => (
  <div className={classNames({ hidden })}>
    <div {...(props as React.HTMLProps<HTMLDivElement>)} />
  </div>
)

function useInitialiseYT(onYoutubeReady: () => void) {
  useEffect(() => {
    window.onYouTubeIframeAPIReady = onYoutubeReady
    const script = document.createElement('script')
    script.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag?.parentNode?.insertBefore(script, firstScriptTag)
  }, [onYoutubeReady])
}

export const VideoPlayer = ({
  videoUrl,
  thumbnailUrl,
  activeIndex,
  setShowVideoSlide,
}: {
  videoUrl: string
  thumbnailUrl?: string
  activeIndex?: number
  setShowVideoSlide?: (value: boolean) => void | undefined
}): JSX.Element => {
  const videoId = useMemo(() => parseId(videoUrl), [videoUrl])
  const [player, setPlayer] = useState<YT.Player | null>(null)
  const idRef = useRef(`betterplace-video-player-${Math.random().toString(36).substr(2, 9)}`)
  const [showVideo, setShowVideo] = useState(!thumbnailUrl)

  const onStateChange = useCallback((event: YT.OnStateChangeEvent) => {
    switch (event.data) {
      case YT.PlayerState.PLAYING:
        setShowVideo(true)
        break
      case YT.PlayerState.PAUSED:
      case YT.PlayerState.ENDED:
        setShowVideo(false)
        document.fullscreenElement && void document.exitFullscreen()
        break
    }
  }, [])

  const onYoutubeReady = useCallback(() => {
    if (!videoId) return
    const p = new YT.Player(idRef.current, {
      videoId,
      events: {
        onStateChange,
        onError: () => setShowVideoSlide && setShowVideoSlide(false),
        // onError didn't work reliably; that's why we check the duration
        onReady: (event: YT.PlayerEvent) => {
          if (!event.target.getDuration() && setShowVideoSlide) setShowVideoSlide(false)
        },
      },
      playerVars: {
        modestbranding: 1,
        rel: 0,
      },
    })
    setPlayer(p)
  }, [videoId, onStateChange, setShowVideoSlide])

  useInitialiseYT(onYoutubeReady)
  useEffect(() => {
    thumbnailUrl && activeIndex && !!player?.pauseVideo && player?.pauseVideo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  const onPlay = useCallback(() => player?.playVideo(), [player])

  return (
    // We need the container and the styles in order to keep 16-9 aspect ratio
    <div className="relative" style={{ paddingTop: thumbnailUrl ? '52.10%' : '56.25%' }}>
      <IFrame
        className="absolute inset-0 w-full h-full"
        allowFullScreen
        id={idRef.current}
        src={videoUrl}
        frameBorder="0"
        hidden={!!thumbnailUrl && !showVideo}
      />

      {thumbnailUrl && player && (
        <div className={classNames(showVideo && 'hidden')}>
          <div
            className={`absolute flex items-center justify-center top-0 left-0 w-full h-full`}
            style={{
              backgroundImage: `url(${thumbnailUrl})`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
          >
            <button className="btn-over-image" tabIndex={0} onClick={onPlay}>
              <i className="fa fa-play-circle pr-1 text-white"></i>
              <span>{i18n.t('misc.play_video')}</span>
            </button>
          </div>
        </div>
      )}
    </div>
  )
}
