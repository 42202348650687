import React, { useCallback, useState } from 'react'
import classnames from 'classnames'
import styles from '../need_editor/need_editor.module.css'
import { EditButton } from './edit_button'
import { ExternalDonationButton } from './external_donation_button'
import { ItemProps, NeedEditorProps, SortableNeedList } from './types'
import { JSONFetcher, PopoverIcon } from 'shared'
import { Sortable } from 'shared/components/sortable'
import { formatCents } from '@betterplace/utils'

// be aware that the needs come unsanitized. Do not use unsafe methods like dangerouslySetInnerHTML!
export const NeedEditor = (props: NeedEditorProps): JSX.Element => {
  const [needs, setNeeds] = useState<SortableNeedList>(JSON.parse(props.needs))

  return <Sortable items={needs} setItems={setNeeds} ItemComponent={Item} />
}

const Item = (props: ItemProps): JSX.Element => {
  const onDuplicateClicked = useCallback(() => {
    JSONFetcher.post({
      url: props.duplicateUrl,
      success: () => window.location.reload(),
    })
  }, [props.duplicateUrl])

  const onDeleteClicked = useCallback(() => {
    if (window.confirm(i18n.t('misc.are_you_sure_dialogue'))) {
      JSONFetcher.delete({
        url: props.deleteUrl,
        success: () => window.location.reload(),
      })
    }
  }, [props.deleteUrl])
  return (
    <>
      <strong>{props.name}</strong>
      <div>
        {i18n.t('modules.need_editor.still_needed_html', { amount: formatCents(i18n.locale, props.stillNeededAmount) })}
      </div>

      <div className={styles.optionsContainer}>
        {props.editable ? (
          <div className={styles.editButtonsContainer}>
            {props.editDe && <EditButton locale="de" {...props} />}
            {props.editEn && <EditButton locale="en" {...props} />}
          </div>
        ) : (
          <span className={classnames('simulated-link', styles.notEditableContainer)}>
            <span>{i18n.t('modules.need_editor.edit_button')}</span>{' '}
            <PopoverIcon i18nKey={'modules.need_editor.edit_disabled_tooltip'} popoverId="tooltip-for-edit-need" />
          </span>
        )}
        <button className={classnames('simulated-link', styles.duplicateButton)} onClick={onDuplicateClicked}>
          {i18n.t('modules.need_editor.duplicate_button')}
        </button>

        <div className={styles.externalNeedsContainer}>
          <ExternalDonationButton {...props} />{' '}
          <PopoverIcon
            i18nKey={'modules.need_editor.external_donation_tooltip'}
            popoverId="tooltip-for-external-donation"
          />
        </div>

        {props.deletable && (
          <button className={classnames('simulated-link font-regular', styles.deleteButton)} onClick={onDeleteClicked}>
            <i className="fa fa-trash-alt fa-xs mr-1"></i>
            <span>{i18n.t('modules.need_editor.delete_button')}</span>
          </button>
        )}

        {!props.deletable && (
          <span className={classnames('font-regular', styles.notDeletableContainer)}>
            <i className="fa fa-trash-alt fa-xs mr-1"></i>
            <span className={styles.notDeletableHint}>{i18n.t('modules.need_editor.delete_button')}</span>
            <PopoverIcon
              i18nKey={'modules.need_editor.delete_disabled_tooltip'}
              popoverId="tooltip-for-need-cannot-be-deleted"
            />
          </span>
        )}
      </div>
    </>
  )
}
