import React, { useCallback, useEffect, useMemo, useState } from 'react'
import classnames from 'classnames'
import { BaseItem, ItemProps, MenuProps, TreeMenuProps } from './types'
import { JSONFetcher, PlainText } from 'shared'

export const TreeMenu = (props: TreeMenuProps): JSX.Element | null => {
  const [items, setItems] = useState<Array<BaseItem>>([])

  useEffect(() => {
    setItems(JSON.parse(props.json))
  }, [props.json])
  if (!items.length) return null
  return <Menu items={items} highlight={props.highlight} />
}

// TODO This needs some re-factoring...

/**
 * @desc view at app/views/layouts/orga_manage.html.slim#36 items via app/lib/manage/menu/organisation_serializer.rb and files of this folder
 */
const Menu = ({
  items = [],
  parentActive,
  parentChildActive,
  parentChildActivate,
  highlight,
}: MenuProps): JSX.Element => {
  const [activeItem_, setActiveItem] = useState<string | undefined>(undefined)
  const [childActiveItem_, setChildActiveItem] = useState<string | undefined>(undefined)
  const [expandedItem, setExpandedItem] = useState<string | undefined>(undefined)
  const itemActivated = useCallback(
    (identifier: string) => {
      setActiveItem(identifier)
      setChildActiveItem(undefined)
      parentChildActivate && parentChildActivate()
    },
    [parentChildActivate]
  )
  const itemChildActivated = useCallback(
    (identifier: string) => {
      setChildActiveItem(identifier)
      setActiveItem(undefined)
      parentChildActivate && parentChildActivate()
    },
    [parentChildActivate]
  )

  useEffect(() => {
    if (highlight && items.find((item) => item.identifier === highlight)) {
      itemActivated(highlight)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, highlight])

  // Set everything inside inactive if parent says so.
  // if () {
  //   if (activeItem) setActiveItem(undefined)
  //   if (childActiveItem) setChildActiveItem(undefined)
  // }
  const parentActiveBlock = useMemo(() => parentActive || !parentChildActive, [parentActive, parentChildActive])
  const activeItem = useMemo(
    () => (!parentActiveBlock && activeItem_ && undefined) || activeItem_,
    [activeItem_, parentActiveBlock]
  )
  const childActiveItem = useMemo(
    () => (!parentActiveBlock && childActiveItem_ && undefined) || childActiveItem_,
    [childActiveItem_, parentActiveBlock]
  )
  return (
    <ol>
      {items &&
        items.map((item) => (
          <Item
            {...item}
            key={item.identifier}
            active={activeItem === item.identifier}
            childActive={childActiveItem === item.identifier}
            activate={() => itemActivated(item.identifier)}
            childActivate={() => itemChildActivated(item.identifier)}
            highlight={highlight}
            expanded={item.identifier === expandedItem}
            expand={(expand) => setExpandedItem((expand && item.identifier) || undefined)}
          />
        ))}
    </ol>
  )
}

const Item = ({
  expand,
  expanded,
  active,
  childActive,
  childActivate,
  modifiers,
  highlight,
  submenu,
  className,
  identifier,
  label,
  href,
}: ItemProps): JSX.Element => {
  const [subitems, setSubitems] = useState(submenu && submenu.items)
  const submenuUrl = submenu && submenu.url
  const hasSubmenu = !!(subitems || submenuUrl)

  // Auto-open active branch.
  useEffect(() => {
    if (active || childActive) expand(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, childActive])

  useEffect(() => {
    if (submenuUrl && expanded) {
      JSONFetcher.load<BaseItem>({
        url: submenuUrl,
        success: (data) => {
          setSubitems(data?.submenu?.items)
        },
      })
    }
  }, [expanded, submenuUrl])

  const toggleFold = useCallback(() => expand(!expanded), [expanded, expand])

  const mods = modifiers || []

  const disabled = mods.includes('disabled')

  const classNames = classnames(className, 'module-tree-menu__item', {
    ['module-tree-menu__item--collapsed']: !expanded,
    ['module-tree-menu__item--expanded']: expanded,
    ['module-tree-menu__item--active']: active,
    ['module-tree-menu__item--child-active']: childActive,
    ['module-tree-menu__item--disabled']: disabled,
    ['module-tree-menu__item--completed']: mods.includes('completed'),
    ['module-tree-menu__item--checkmarked']: mods.includes('checkmarked'),
    ['module-tree-menu__item--placeholder']: mods.includes('placeholder'),
    ['module-tree-menu__item--optional']: mods.includes('optional'),
  })

  const tooltip = identifier.includes('organisation')
    ? i18n.t('manage.organisations.menu.organisation_blocked_hint')
    : i18n.t('manage.organisations.menu.project_blocked_hint')

  const togglingOnly = hasSubmenu && !mods.includes('checkmarked')

  let WrapperTag: keyof HTMLElementTagNameMap = 'a'
  if (disabled) WrapperTag = 'div'
  if (togglingOnly) WrapperTag = 'button'

  return (
    <li className={classNames}>
      <WrapperTag
        className="module-tree-menu__item__label"
        onClick={disabled || !hasSubmenu ? undefined : toggleFold}
        href={disabled || togglingOnly ? undefined : href}
        title={disabled ? tooltip : undefined}
      >
        {mods.includes('optional') ? <span className="module-tree-menu__item--optional-label">{'optional'}</span> : ''}
        {mods.includes('blocked') ? (
          <span className="module-tree-menu__item--blocked-label">
            {i18n.t('manage.organisations.menu.project_blocked_label')}
          </span>
        ) : (
          ''
        )}
        {mods.includes('preparing') ? (
          <span className="module-tree-menu__item--preparing-label">
            {i18n.t('manage.organisations.menu.project_preparing_label')}
          </span>
        ) : (
          ''
        )}
        {mods.includes('proposed') ? (
          <span className="module-tree-menu__item--proposed-label">
            {i18n.t('manage.organisations.menu.project_proposed_label')}
          </span>
        ) : (
          ''
        )}
        {/* The label is already sanitised on the backend */}
        <PlainText as="div">{label}</PlainText>
        {hasSubmenu && (
          <i
            className={`ml-2 fa fa-angle-right module-tree-menu__item__toggle ${
              expanded ? 'module-tree-menu__item__toggle--expanded' : ''
            }`}
          />
        )}
      </WrapperTag>
      {hasSubmenu && (
        <Menu
          items={subitems}
          parentActive={active}
          parentChildActive={childActive}
          parentChildActivate={childActivate}
          highlight={highlight}
        />
      )}
    </li>
  )
}
