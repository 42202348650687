import React, { useCallback, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { BaseItem, ClosedProductListProps, FilterSelectionProps } from './types'
import { PlainText, useModal, useOnEscapePressed } from 'shared'

const ListItem = ({
  onClick,
  title,
  selected,
}: {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  title: string
  selected?: boolean
}) => {
  return (
    <li className={classNames('module-filter-selection__item', 'p-2', { 'font-bold': selected })}>
      <PlainText as="button" onClick={onClick}>
        {title}
      </PlainText>
    </li>
  )
}

export const FilterSelection = <T extends BaseItem>(props: FilterSelectionProps<T>): JSX.Element => {
  const items: Array<T> = JSON.parse(props.items)
  const secondaryItems: Array<T> = JSON.parse(props.secondaryItems)
  const showSecondary = secondaryItems && secondaryItems.length > 0
  const activeItem: T | undefined = items.find((item) => item.title === props.title)
  const activeItemIsSecondaryItem = !activeItem
  const modalContentRef = useRef(null)
  const modal = useModal()
  const handleClick: (item: T) => void = useMemo(
    () =>
      props.onClick ||
      ((item) => {
        window.location.href = item.url
      }),
    [props.onClick]
  )
  useOnEscapePressed(() => modal.hide)

  return (
    <div className="module-filter-selection">
      <button className="module-filter-selection__toggle" onClick={modal.show}>
        {props.title}
      </button>
      {modal.background}
      {modal.active && (
        <ul ref={modalContentRef} className="module-filter-selection__content mt-1">
          {items.map((item, index) => (
            <ListItem onClick={() => handleClick(item)} key={index} title={item.title} selected={item === activeItem} />
          ))}
          {showSecondary && (
            <ClosedProductList activeItemIsSecondaryItem={activeItemIsSecondaryItem}>
              {secondaryItems.map((item, index) => (
                <ListItem
                  onClick={() => handleClick(item)}
                  key={index}
                  title={item.title}
                  selected={item.title === props.title}
                />
              ))}
            </ClosedProductList>
          )}
        </ul>
      )}
    </div>
  )
}

const ClosedProductList = (props: ClosedProductListProps): JSX.Element => {
  const [showClosedProducts, setShowClosedProducts] = useState(props.activeItemIsSecondaryItem)
  const toggle: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => setShowClosedProducts((old) => !old), [])
  return (
    <div>
      <button className="simulated-link p-2 cursor-pointer" onClick={toggle}>
        {!showClosedProducts
          ? i18n.t('modules.filter_selection.show_closed_projects')
          : i18n.t('modules.filter_selection.hide_closed_projects')}
      </button>
      {showClosedProducts && props.children}
    </div>
  )
}
