import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Field, Form, Formik, FormikErrors } from 'formik'

import { Button, FormGroup, FormTextArea, I18nHtml, JSONFetcher, Modal, TrustedHtml, useJsonApi } from 'shared'

import {
  DonorDetailsApiResult,
  DonorDetailsProps,
  FormContentProps,
  FormikSubmitHandler,
  TYButtonProps,
  ThankYouMessageButtonProps,
} from './types'

export const ThankYouMessageButton = (props: ThankYouMessageButtonProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState(props)

  const hideModal = useCallback(() => setShowModal(false), [])
  const openModal: React.MouseEventHandler = useCallback((event) => {
    if (event) event.preventDefault()
    setShowModal(true)
  }, [])

  return (
    <div>
      <TYButton {...data} onClick={openModal} />

      <Modal
        id="thank-you-modal"
        onHide={hideModal}
        show={showModal}
        header={i18n.t('modules.thank_you_message.box_headline', { name: data.to })}
        size="modal-lg"
      >
        {!data.sentAt && (
          <I18nHtml
            as="p"
            i18nKey="modules.thank_you_message.introduction_html"
            options={{ expires_at: data.expiresAt }}
          />
        )}
        {data.sentAt && (
          <p>
            {i18n.t('modules.thank_you_message.sent_at_notice', {
              sent_at: data.sentAt,
            })}
          </p>
        )}
        <FormContent {...data} setData={setData} closeModal={hideModal} />
      </Modal>
    </div>
  )
}

const TYButton = ({ state, onClick }: TYButtonProps): JSX.Element => {
  if (state === 'sent')
    return (
      <>
        <i className="fa fa-check text-green-500 mr-1" />
        <span>{i18n.t('modules.thank_you_message.sent_label')}</span>
        <button className="simulated-link block" onClick={onClick}>
          {i18n.t('modules.thank_you_message.view_label')}
        </button>
      </>
    )
  if (state === 'expired') return <>{i18n.t('modules.thank_you_message.expired_label')}</>
  if (state === 'not_available') return <>{i18n.t('modules.thank_you_message.not_available_label')}</>

  return (
    <button className="btn btn-primary" onClick={onClick}>
      {i18n.t('modules.thank_you_message.write_label')}
    </button>
  )
}

const FormContent = ({ submitUrl, setData, closeModal, ...props }: FormContentProps) => {
  const initialValues = {
    from: props.from,
    to: props.to,
    subject: props.subject,
    body: props.body,
  }
  const formRef = useRef<HTMLFormElement | null>(null)

  const submitHandler: FormikSubmitHandler<typeof initialValues> = useCallback(
    (values, formikBag) => {
      JSONFetcher.post({
        url: submitUrl,
        body: { body: values.body },
        success: (response: ThankYouMessageButtonProps) => {
          formikBag.setSubmitting(false)
          setData(response)
          closeModal()
        },
      })
    },
    [submitUrl, setData, closeModal]
  )

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formRef.current?.querySelector('textarea')?.focus()
  }, [formRef])

  const validate = useCallback((values: typeof initialValues) => {
    const errors: FormikErrors<typeof initialValues> = {}
    if (!values.body) errors.body = i18n.t('misc.required_field')
    else if (values.body.length < 5) errors.body = i18n.t('errors.messages.too_short', { count: 5 })
    else if (values.body.length > 2048) errors.body = i18n.t('errors.messages.too_long', { count: 2048 })
    return errors
  }, [])

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler} validate={validate}>
      {(formikProps) => (
        <Form ref={formRef}>
          <div className="border-t border-b border-gray-300 py-3 mb-4">
            <DonorDetails to={props.to} donorDetailsUrl={props.donorDetailsUrl} />
          </div>
          <div className="row">
            <div className="col-12">
              <Field
                name="from"
                label={i18n.t('modules.thank_you_message.from_label')}
                readOnly
                disabled
                component={FormGroup}
                groupClass="mb-0"
              />
              <small className="form-text text-muted mb-3">
                {i18n.t('modules.thank_you_message.reply_to_text')} {props.replyTo}
              </small>
            </div>
            <div className="col-12">
              <Field
                name="to"
                label={i18n.t('modules.thank_you_message.to_label')}
                readOnly
                disabled
                component={FormGroup}
              />
            </div>
          </div>
          <Field
            name="subject"
            label={i18n.t('modules.thank_you_message.subject_label')}
            readOnly
            disabled
            component={FormGroup}
          />
          <Field
            name="body"
            label={i18n.t('modules.thank_you_message.body_label')}
            readOnly={props.sentAt}
            disabled={props.sentAt}
            component={FormTextArea}
            maxLength={2048}
            rows={3}
          />
          <TrustedHtml as="p">{props.greetings}</TrustedHtml>
          {props.state === 'new' && (
            <Button type="submit" loading={formikProps.isSubmitting} className="btn-large btn-primary">
              {i18n.t('modules.thank_you_message.submit_button')}
            </Button>
          )}
        </Form>
      )}
    </Formik>
  )
}

const DonorDetails = ({ donorDetailsUrl, to }: DonorDetailsProps): JSX.Element | null => {
  const { data: details } = useJsonApi<DonorDetailsApiResult>(donorDetailsUrl)
  if (!details) return null

  return (
    <>
      <div>
        <strong>{i18n.t('modules.thank_you_message.donor_details_label')}</strong>
      </div>
      {details.summary}

      {details.comment && (
        <div className="mt-2">
          <strong>{i18n.t('modules.thank_you_message.donor_details_message')}</strong>
        </div>
      )}
      {details.comment}

      <div className="mt-2">
        <strong>{i18n.t('modules.thank_you_message.donor_details_headline', { name: to })}</strong>
      </div>
      <ul className="m-0 font-size-14">
        {details.additional.map((a, i) => (
          <li key={i}>{a}</li>
        ))}
      </ul>
    </>
  )
}
