import React, { useCallback, useState } from 'react'
import { Button, FormCentsInput, FormTextArea, JSONFetcher } from 'shared'
import { formatCents } from '@betterplace/utils'

import {
  DonationButtonFormErrors,
  DonationButtonFormSubmitHandler,
  DonationButtonFormValidator,
  SortableNeed,
} from './types'
import { Field, Form, Formik } from 'formik'
import { Modal } from 'shared'

export const ExternalDonationButton = (props: SortableNeed): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const hideModal = () => setShowModal(false)
  const openModal = () => setShowModal(true)

  return (
    <>
      <button className="whitespace-nowrap simulated-link" onClick={openModal}>
        {i18n.t('modules.need_editor.external_donation')}
      </button>

      <Modal
        id="external-donation-modal"
        onHide={hideModal}
        show={showModal}
        header={i18n.t('modules.need_editor.external_donation_modal_headline')}
      >
        <p>{i18n.t('modules.need_editor.external_donation_introduction')}</p>
        <FormContent {...props} />
      </Modal>
    </>
  )
}

const FormContent = ({ externalDonationsUrl, stillNeededAmount }: SortableNeed): JSX.Element => {
  const initialValues = {
    amount_in_cents: 0,
    comment: '',
  }

  // TODO: There should be a way to pass validation rules from ActiveRecord to React. Will check that out.
  const maxCommentLength = 250
  const submitHandler: DonationButtonFormSubmitHandler = useCallback(
    (values, formikBag) => {
      JSONFetcher.post({
        url: externalDonationsUrl,
        body: { external_donation: values },
        success: (resp: { errors?: string[] }) => {
          if (resp.errors) {
            const errors: DonationButtonFormErrors = {}
            resp.errors.forEach((/** @type {string}*/ error: string) => {
              // We assert that `error` is a string starting with the name of the field it refers to.
              const [subject] = error.split(' ', 1)
              const issue = error.slice((subject?.length ?? 0) + 1)
              switch (subject) {
                case 'Comment':
                  errors.comment = issue
                  break
                case 'Amount':
                  errors.amount_in_cents = issue
                  break
              }
            })
            formikBag.setErrors(errors)
            formikBag.setSubmitting(false)
          } else {
            window.location.reload()
          }
        },
      })
    },
    [externalDonationsUrl]
  )
  const validate: DonationButtonFormValidator = useCallback(
    (values) => {
      const errors: DonationButtonFormErrors = {}
      if (values.amount_in_cents <= 0) errors.amount_in_cents = i18n.t('misc.required_field')
      else if (values.amount_in_cents > stillNeededAmount)
        errors.amount_in_cents = i18n.t('errors.messages.less_than_or_equal_to_amount', {
          amount: formatCents(i18n.locale, stillNeededAmount, { format: '%v' }),
        })
      if (values.comment.length > maxCommentLength) errors.comment = i18n.t('errors.messages.too_long')
      return errors
    },
    [stillNeededAmount]
  )

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler} validate={validate}>
      {({ isSubmitting }) => (
        <Form>
          <Field
            name="amount_in_cents"
            label={i18n.t('modules.need_editor.external_donation_amount_label')}
            // TODO: Would be cool to have sth. like this: max={stillNeededAmount}
            hint={i18n.t('modules.need_editor.external_donation_amount_hint', {
              still_needed_amount: formatCents(i18n.locale, stillNeededAmount),
            })}
            component={FormCentsInput}
          />

          <Field
            name="comment"
            label={i18n.t('modules.need_editor.external_donation_comment_label')}
            component={FormTextArea}
            maxLength={maxCommentLength}
            rows={3}
            onKeyDown={(event: React.KeyboardEvent) => event.stopPropagation()} //Ignore Sortable's event handlers
          />

          <Button type="submit" loading={isSubmitting} className="btn-large btn-primary">
            {i18n.t('verbs.save')}
          </Button>
        </Form>
      )}
    </Formik>
  )
}
