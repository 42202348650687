import Autosuggest, { SuggestionsFetchRequested } from 'react-autosuggest'
import CountryList from 'i18n-iso-countries'
import React, { useCallback, useState } from 'react'
import de from 'i18n-iso-countries/langs/de.json'
import en from 'i18n-iso-countries/langs/en.json'
import {
  AddressFieldsOuterProps,
  AddressFieldsValues,
  OnStreetSuggestionSelected,
  StreetAutoSuggestOuterProps,
  StreetSuggestion,
  SuggestionList,
} from './types'
import { Field, connect } from 'formik'
import { FormGroup, JSONFetcher } from 'shared'
import { FormGroupProps } from 'shared/components/form_group/types'
CountryList.registerLocale(de)
CountryList.registerLocale(en)
const NUMERIC_ZIP_COUNTRY_CODES = [
  'RU',
  'DE',
  'TR',
  'FR',
  'IT',
  'ES',
  'PL',
  'RO',
  'BE',
  'GR',
  'CZ',
  'PT',
  'HU',
  'CH',
  'BG',
  'RS',
  'DK',
  'FI',
  'SK',
  'NO',
]

export const AddressFields: React.ComponentType<AddressFieldsOuterProps> = connect<
  AddressFieldsOuterProps,
  AddressFieldsValues
>(({ formik, required }) => {
  const countries = CountryList.getNames(i18n.locale)
  const countryOptions = Object.keys(countries).map((alpha2) => (
    <option value={alpha2} key={alpha2}>
      {countries[alpha2]}
    </option>
  ))
  const numericZip = NUMERIC_ZIP_COUNTRY_CODES.includes(formik.values.country_alpha2)
  const buildLabel = useCallback((name) => `${i18n.t(`donate.attributes.${name}`)}${required ? '*' : ''}`, [required])

  const cityAutoFill = useCallback(() => {
    void formik.setFieldTouched('zip', true)
    if (formik.values.country_alpha2 === 'DE' && formik.values.zip.length === 5) {
      JSONFetcher.load<{ city?: string }>({
        url: `/de/zip_cities/${formik.values.zip}.json`,
        success: (response) => response.city && void formik.setFieldValue('city', response.city),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.zip])

  return (
    <div className="flex flex-wrap">
      <div className="form-group desktop:w-1/3 inline-block">
        <label className="control-label sr-only" htmlFor="country_alpha2">
          {buildLabel('country')}
        </label>
        <Field
          component="select"
          className="form-control"
          aria-required="true"
          name="country_alpha2"
          id="country_alpha2"
        >
          {countryOptions}
        </Field>
      </div>

      <Field
        name="zip"
        placeholder={buildLabel('zip')}
        label={buildLabel('zip')}
        component={FormGroup}
        groupClass="w-1/3 desktop:w-1/4 desktop:pl-2 pr-2 inline-block"
        labelClass="sr-only"
        type="text"
        pattern={numericZip ? '[0-9]*' : null}
        inputMode={numericZip ? 'tel' : 'text'}
        onBlur={cityAutoFill}
      />

      <Field
        name="city"
        labelClass="sr-only"
        groupClass="w-2/3 desktop:w-5/12 inline-block"
        placeholder={buildLabel('city')}
        label={buildLabel('city')}
        component={FormGroup}
      />

      <Field
        placeholder={buildLabel('street')}
        label={buildLabel('street')}
        name="street"
        component={StreetAutosuggest}
        labelClass="sr-only"
        groupClass="w-full"
      />
    </div>
  )
})

const v2v = <T,>(v: T) => v

const suggestionToValue = (s: StreetSuggestion) => s.suggestion

const StreetAutosuggest = connect<StreetAutoSuggestOuterProps, AddressFieldsValues>((props) => {
  const [suggestions, setSuggestions] = useState<SuggestionList>([])

  const { field, formik, placeholder } = props
  const clearSuggestions = useCallback(() => setSuggestions([]), [])
  const fetchSuggestions: SuggestionsFetchRequested = useCallback(
    ({ value }) => {
      if (formik.values.country_alpha2 !== 'DE' || value.length < 1 || formik.values.zip.length !== 5) return
      JSONFetcher.load<{ streets?: SuggestionList }>({
        url: `/de/zip_streets.json?zip=${formik.values.zip}&street=${value}`,
        success: (response) => {
          if (!response.streets) return
          setSuggestions(response.streets)
        },
      })
    },
    [formik.values.country_alpha2, formik.values.zip]
  )

  const handleSuggestionSelected: OnStreetSuggestionSelected = useCallback(
    (event, suggestion) => {
      if (suggestion.method === 'enter') event.preventDefault()
      formik.handleChange(event)
      void formik.setFieldValue('street', String(suggestion.suggestion) + ' ')
    },
    [formik]
  )

  return (
    <FormGroup {...(props as FormGroupProps)}>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={fetchSuggestions}
        onSuggestionsClearRequested={clearSuggestions}
        onSuggestionSelected={handleSuggestionSelected}
        getSuggestionValue={suggestionToValue}
        renderSuggestion={v2v}
        inputProps={{ ...field, placeholder }}
      />
    </FormGroup>
  )
})
