import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './lottie_animation.module.css'
import { LottieAnimationProps } from './types'
import { handleError } from 'shared'
import type { LottiePlayer } from 'lottie-web'

export const LottieAnimation = ({ file }: LottieAnimationProps): JSX.Element => {
  const container = useRef<HTMLDivElement | null>(null)
  const [lottie, setLottie] = useState<LottiePlayer | null>(null)
  // we need this wrapper, otherwise the import which is a function will be treated as reducer
  const setLottieFn = useCallback((fn: LottiePlayer) => setLottie(() => fn), [setLottie])
  useEffect(() => {
    import('lottie-web')
      .then((m) => m.default)
      .then(setLottieFn)
      .catch(handleError)
  }, [setLottieFn])
  useEffect(() => {
    if (!container.current || !lottie) return
    const animation = lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      autoplay: false,
      loop: true,
      path: file,
    })
    const mainLoop = () => {
      animation.playSegments([300, 900], true)
      animation.removeEventListener('loopComplete')
    }

    animation.playSegments([0, 300], true)
    animation.removeEventListener('loopComplete')
    animation.addEventListener('loopComplete', mainLoop)
  }, [file, lottie])

  return <div ref={container} className={styles.container} />
}
