import React, { useMemo } from 'react'
import styles from './project_swiper.module.css'
import { A11y, Mousewheel, Navigation, Pagination } from 'swiper/modules'
import {
  NavigationArrow,
  PopoverIcon,
  ProjectPreview,
  ProjectTeaserWithTracking,
  SwiperNavigation,
  themeBreakpoint,
  useJsonApi,
} from 'shared'
import { ProjectSwiperProps, ProjectsResult } from './types'
import { Swiper, SwiperSlide } from 'swiper/react'

export function useSortedProjects({
  apiUrl,
  orderedIds: stringifiedIds,
}: Pick<ProjectSwiperProps, 'apiUrl' | 'orderedIds'>) {
  const { data, loading } = useJsonApi<ProjectsResult>(apiUrl)
  const projects = useMemo(() => {
    const unsortedProjects = data?.data
    if (!unsortedProjects) return []
    if (!stringifiedIds) return unsortedProjects
    const orderedIds: number[] = JSON.parse(stringifiedIds)
    return [...unsortedProjects].sort((a, b) => {
      const indexA = orderedIds.indexOf(a.id)
      const indexB = orderedIds.indexOf(b.id)
      if (indexA === indexB) return 0 // both are -1 -> keep order from API
      if (indexA === -1) return 1 // sort b before a
      if (indexB === -1) return -1 // sort a before b
      return indexA - indexB // sort based on their index in orderedIds
    })
  }, [stringifiedIds, data])
  return { projects, loading }
}

export const ProjectSwiper = (props: ProjectSwiperProps): JSX.Element => {
  const id = useMemo(() => Math.random().toString(36).slice(2, 9), [])
  const { projects, loading } = useSortedProjects(props)
  const slidesPerView = 3
  const breakpoint = themeBreakpoint()

  if (loading) {
    const numberOfPreviews = window.innerWidth >= breakpoint ? slidesPerView : 1
    return (
      <div className="row">
        {[...Array(numberOfPreviews).keys()].map((i) => (
          <div className="col-md-8" key={i}>
            <ProjectPreview />
          </div>
        ))}
      </div>
    )
  }

  if (!projects.length) return <NoProjectsHint />
  const slides = projects.map((project, index) => (
    <SwiperSlide key={project.id}>
      <ProjectTeaserWithTracking
        project={project}
        index={index}
        trackingPath="/home-page/featured-projects"
        showDescription={!!props.showDescription}
        progressbarColor={props.progressbarColor}
        className="w-full"
      />
    </SwiperSlide>
  ))

  if (projects.length === 1) return <div className="desktop:w-1/3 desktop:pr-3">{slides}</div>

  const showNavigationArrows = props.pagination === 'chevron' && slides.length > slidesPerView

  return (
    <div className="relative">
      {props.pagination === 'arrow-buttons' && projects.length >= 4 && (
        <div className={styles.paginationContainer}>
          <SwiperNavigation prefix={`featured-list-${id}`} />
        </div>
      )}
      <div className="generic-teaser-swiper select-none">
        {showNavigationArrows && (
          <div className="swiper-navigation swiper-navigation-left">
            <NavigationArrow id={`featured-list-${id}-prev`} direction="left" />
          </div>
        )}
        <Swiper
          modules={[Navigation, Pagination, Mousewheel, A11y]}
          className="pb-0"
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          navigation={{
            nextEl: `#featured-list-${id}-next`,
            prevEl: `#featured-list-${id}-prev`,
            disabledClass: 'is-disabled',
          }}
          roundLengths
          mousewheel={{ forceToAxis: true }}
          breakpoints={{ [breakpoint]: { slidesPerView } }}
        >
          {slides}
        </Swiper>
        {showNavigationArrows && (
          <div className="swiper-navigation swiper-navigation-right">
            <NavigationArrow id={`featured-list-${id}-next`} direction="right" />
          </div>
        )}
      </div>
    </div>
  )
}

const NoProjectsHint = (): JSX.Element | null => {
  if (window.location.pathname.search('/manage/') < 0) return null
  return (
    <>
      <span className={styles.noProjectsHint}>{i18n.t('manage.dashboard.no_projects_message')}</span>
      <PopoverIcon i18nKey="manage.dashboard.no_projects_popover_html" popoverId="tooltip-for-empty-project-swiper" />
    </>
  )
}
