import React, { useCallback, useState } from 'react'
import { Button, FormCentsInput, FormGroup, FormTextArea, JSONFetcher, TrustedHtml } from 'shared'
import { EditButtonProps, FormSubmitHandler, FormValidator } from './types'
import { Field, Form, Formik, FormikErrors } from 'formik'
import { Modal } from 'shared'
import { formatCents } from '@betterplace/utils'

/**
 * @TODO Determine where is the content prop coming from - is it an artifact here?
 */
export const EditButton = (props: EditButtonProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false)
  const hideModal = useCallback(() => setShowModal(false), [])
  const openModal = useCallback(() => setShowModal(true), [])

  return (
    <>
      <button className="whitespace-nowrap desktop:mr-2 simulated-link" onClick={openModal}>
        {i18n.t(`modules.need_editor.edit_${props.locale}_link`)}
      </button>
      <Modal
        id="thank-you-modal"
        onHide={hideModal}
        show={showModal}
        header={i18n.t(`modules.need_editor.edit_modal_headline_${props.locale}`)}
      >
        {props.content && <TrustedHtml as="div">{props.content}</TrustedHtml>}
        {props.children}
        <FormContent {...props} />
      </Modal>
    </>
  )
}

const buildInitialValues = (props: EditButtonProps) => {
  return {
    de: {
      name_de: props.nameDe,
      summary_de: props.summaryDe,
      requested_amount_in_cents: props.requestedAmountInCents,
    },
    en: {
      name_en: props.nameEn,
      summary_en: props.summaryEn,
      requested_amount_in_cents: props.requestedAmountInCents,
    },
  }[props.locale]
}

const FormContent = (props: EditButtonProps) => {
  const initialValues = buildInitialValues(props)

  const submitHandler: FormSubmitHandler<typeof initialValues> = useCallback(
    (values) => {
      JSONFetcher.put({
        url: props.updateUrl,
        body: { need: values },
        success: () => window.location.reload(),
      })
    },
    [props.updateUrl]
  )

  const validate: FormValidator<typeof initialValues> = useCallback((values) => {
    const errors: FormikErrors<typeof initialValues> = {}
    const maxAmount = 9999
    if (!values.requested_amount_in_cents) errors.requested_amount_in_cents = i18n.t('misc.required_field')
    else if (values.requested_amount_in_cents > maxAmount * 100)
      errors.requested_amount_in_cents = i18n.t('errors.messages.less_than_or_equal_to_amount', {
        amount: formatCents(i18n.locale, maxAmount, { format: '%v' }),
      })
    return errors
  }, [])

  return (
    <Formik initialValues={initialValues} onSubmit={submitHandler} validate={validate}>
      {({ isSubmitting }) => (
        <Form>
          <Field
            name={`name_${props.locale}`}
            label={i18n.t('modules.need_editor.name_label')}
            hint={i18n.t('modules.need_editor.name_hint')}
            component={FormGroup}
            onKeyDown={(event: React.KeyboardEvent) => event.stopPropagation()} //Ignore Sortable's event handlers
          />
          <Field
            name="requested_amount_in_cents"
            label={i18n.t('modules.need_editor.requested_amount_label')}
            hint={i18n.t('modules.need_editor.requested_amount_hint')}
            component={FormCentsInput}
            onKeyDown={(event: React.KeyboardEvent) => event.stopPropagation()} //Ignore Sortable's event handlers
          />

          <Field
            name={`summary_${props.locale}`}
            label={i18n.t('modules.need_editor.summary_label')}
            hint={i18n.t('modules.need_editor.summary_hint')}
            component={FormTextArea}
            maxLength={250}
            rows={3}
            onKeyDown={(event: React.KeyboardEvent) => event.stopPropagation()} //Ignore Sortable's event handlers
          />

          <Button type="submit" loading={isSubmitting} className="btn-large btn-primary">
            {i18n.t('verbs.save')}
          </Button>
          <div className="help-block">
            <h3 className="text-2xl">{i18n.t('modules.need_editor.notes_label')}</h3>
            <ul>
              <li>{i18n.t('modules.need_editor.note_precision')}</li>
              <li>{i18n.t('modules.need_editor.note_edit')}</li>
            </ul>
          </div>
        </Form>
      )}
    </Formik>
  )
}
