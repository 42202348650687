import React, { useCallback, useEffect, useRef, useState } from 'react'
import { NavigationSearchProps } from './types'

export const NavigationSearch = ({ searchUrl }: NavigationSearchProps): JSX.Element => {
  const [open, setOpen] = useState(false)
  const formRef = useRef<HTMLFormElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const showSearch = useCallback(() => {
    document.querySelector('header')?.classList.add('search-is-open')
    setOpen(true)
  }, [])

  useEffect(() => {
    if (open === true) {
      inputRef.current && inputRef.current.focus()
    }
  }, [open])

  const submitSearch = useCallback(() => formRef.current?.submit(), [])
  if (!open) {
    return (
      <button onClick={showSearch} className="header-search-icon">
        <i className="fa fa-search" />
        <span className="sr-only">{i18n.t('verbs.search')}</span>
      </button>
    )
  }

  return (
    <form action={searchUrl} method="POST" ref={formRef}>
      <input
        type="search"
        className="form-control"
        name="query"
        placeholder={i18n.t('layouts.header.search_placeholder')}
        ref={inputRef}
      />

      <button onClick={submitSearch} className="header-search-icon">
        <i className="fa fa-search" />
        <span className="sr-only">{i18n.t('verbs.search')}</span>
      </button>
    </form>
  )
}
