import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Dropzone } from 'shared/dropzone'
import { I18nHtml, JSONFetcher, LogoSpinner, Modal, handleError, themeColor } from 'shared'
try {
  require('trix')
} catch (_err) {}
import 'trix/dist/trix.css'
import sanitize from 'sanitize-html'
import {
  EditorElement,
  ImageSelectorProps,
  ImageUploadButtonProps,
  ImagesButtonAndModalProps,
  Img,
  PasteEvent,
  TrixEditorProps,
  TrixToolbarProps,
} from './types'

const generateId = () => `trix-input-${Math.round(Math.random() * 10000)}`

export const TrixEditor = (props: TrixEditorProps): JSX.Element => {
  const hiddenInputId = useMemo(() => props.hiddenField ?? generateId(), [props.hiddenField])
  const editorElement: React.MutableRefObject<HTMLElement | null> = useRef(null)

  useEffect(() => {
    function handleChange(event: Event) {
      const change = (event.target as HTMLElement).innerHTML
      props.onChange && props.onChange(change)
    }
    function handlePaste(event_: Event) {
      const event = event_ as unknown as PasteEvent
      if (event.paste.type === 'text/html') {
        event.paste.html = sanitize(event.paste.html)
      }
    }
    const currentEditorElement = editorElement.current
    if (!currentEditorElement) return

    currentEditorElement.addEventListener('trix-change', handleChange)
    currentEditorElement.addEventListener('trix-before-paste', handlePaste)
    return () => {
      currentEditorElement.removeEventListener('trix-change', handleChange)
      currentEditorElement.removeEventListener('trix-before-paste', handlePaste)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Toolbar {...props} />
      <trix-editor toolbar="trix-toolbar" input={hiddenInputId} ref={editorElement} />
      {!props.hiddenField && <input type="hidden" id={hiddenInputId} value={props.value} />}
    </>
  )
}

const Toolbar = ({ imagesUrl, imageUploadUrl, showVideoButton }: TrixToolbarProps) => {
  return (
    <trix-toolbar className="trix-toolbar" id="trix-toolbar">
      <div className="trix-button-row">
        <span className="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
          <button
            className="trix-button trix-button--icon trix-button--icon-bold"
            data-trix-attribute="bold"
            data-trix-key="b"
            tabIndex={-1}
            type="button"
          >
            {i18n.t('modules.trix_editor.button_bold')}
          </button>
          <button
            className="trix-button trix-button--icon trix-button--icon-italic italic"
            data-trix-attribute="italic"
            data-trix-key="i"
            tabIndex={-1}
            type="button"
          >
            {i18n.t('modules.trix_editor.button_italic')}
          </button>
          <button
            className="trix-button trix-button--icon trix-button--icon-bullet-list list bullets"
            data-trix-attribute="bullet"
            tabIndex={-1}
            type="button"
          >
            <span>···</span>
            <span>{i18n.t('modules.trix_editor.button_list')}</span>
          </button>
          <button
            className="trix-button trix-button--icon trix-button--icon-link link"
            data-trix-action="link"
            data-trix-attribute="href"
            data-trix-key="k"
            type="button"
          >
            {i18n.t('modules.trix_editor.button_link')}
          </button>
          {showVideoButton && <VideosButtonAndModal />}
          {imagesUrl && <ImagesButtonAndModal imagesUrl={imagesUrl} />}
          {imageUploadUrl && <ImageUploadButton imageUploadUrl={imageUploadUrl} />}
        </span>

        <button data-trix-action="undo" data-trix-key="z" style={{ display: 'none' }} type="button"></button>
        <button data-trix-action="redo" data-trix-key="shift+z" style={{ display: 'none' }} type="button"></button>
      </div>

      <div className="trix-dialogs" data-trix-dialogs="">
        <div className="trix-dialog trix-dialog--link" data-trix-dialog="href" data-trix-dialog-attribute="href">
          <div className="trix-dialog__link-fields">
            <input
              className="trix-input trix-input--dialog"
              data-trix-input=""
              name="href"
              placeholder="URL"
              type="url"
              disabled
            />
            <div className="trix-button-group">
              <input
                className="trix-button trix-button--dialog"
                data-trix-method="setAttribute"
                type="button"
                value={i18n.t('modules.trix_editor.button_add_link')}
              />
              <input
                className="trix-button trix-button--dialog"
                data-trix-method="removeAttribute"
                type="button"
                value={i18n.t('modules.trix_editor.button_unlink')}
              />
            </div>
          </div>
        </div>
      </div>
    </trix-toolbar>
  )
}

const ImagesButtonAndModal = ({ imagesUrl }: ImagesButtonAndModalProps): JSX.Element => {
  const [visiblePhotoModal, setVisiblePhotoModal] = useState(false)
  const openPhotoModal = useCallback(() => setVisiblePhotoModal(true), [])
  const hidePhotoModal = useCallback(() => setVisiblePhotoModal(false), [])
  const [loading, setLoading] = useState(false)

  const [images, setImages] = useState<Array<Img> | undefined>([])

  useEffect(() => {
    const ctrl = new AbortController()
    JSONFetcher.call<unknown, Array<Img>>({ url: imagesUrl, signal: ctrl.signal })
      .then((resp) => {
        setImages(resp)
      })
      .catch(handleError)
    return () => ctrl.abort()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpload = useCallback(
    (acceptedFiles: Array<File>) => {
      acceptedFiles.forEach((file) => {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', file)

        JSONFetcher.call<FormData, Array<Img>>({
          url: imagesUrl,
          method: 'post',
          body: formData,
          config: {
            withCredentials: true,
            headers: {
              'Content-Type': undefined,
            },
          },
        })
          .then((data) => {
            setImages(data)
            setLoading(false)
          })
          .catch(handleError)
      })
    },
    [imagesUrl]
  )

  return (
    <>
      <button className="trix-button" type="button" onClick={openPhotoModal}>
        {i18n.t('modules.trix_editor.button_picture_insert')}
      </button>

      <Modal
        id="photo-modal"
        onHide={hidePhotoModal}
        show={visiblePhotoModal}
        header={i18n.t('modules.trix_editor.picture_modal_message.title')}
      >
        <Dropzone
          accept={['image/jpg', 'image/jpeg', 'image/tif', 'image/tiff', 'image/png']}
          maxSize={20000000}
          multiple={true}
          handleUpload={handleUpload}
          buttonOnly
          loading={loading}
        />
        {images ? (
          <p>{i18n.t('modules.trix_editor.picture_modal_message.explanation_html')}</p>
        ) : (
          <p className="my-2">{i18n.t('modules.trix_editor.picture_modal_message.zero_pictures_warning')}</p>
        )}
        <div className="flex flex-wrap my-2">
          {images?.map((img, index) => <ImageSelector {...img} key={index} closeModal={hidePhotoModal} />)}
        </div>
      </Modal>
    </>
  )
}

const LoadingStyle: React.CSSProperties = {
  color: 'transparent',
  textShadow: 'none',
  lineHeight: 0,
  transition: 'none',
}

const ImageUploadButton = ({ imageUploadUrl }: ImageUploadButtonProps): JSX.Element => {
  const [loading, setLoading] = useState(false)

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (!event.target.files || !event.target.files[0]) return
      setLoading(true)
      const formData = new FormData()
      formData.append('file', event.target.files[0])

      JSONFetcher.call<FormData, Img>({
        url: imageUploadUrl,
        method: 'post',
        body: formData,
        config: {
          withCredentials: true,
          headers: {
            'Content-Type': undefined,
          },
        },
      })
        .then((data) => {
          // TODO: This breaks pages with multiple TrixEditor instances.
          const trixEditor = (document.getElementsByTagName('trix-editor')[0] as EditorElement).editor
          trixEditor.insertString(data.url)
          trixEditor.insertLineBreak()
          trixEditor.insertLineBreak()
          setLoading(false)
        })
        .catch(handleError)
    },
    [imageUploadUrl]
  )

  return (
    <>
      <label
        htmlFor="image-upload"
        className="trix-button flex items-center justify-center"
        style={(loading && LoadingStyle) || undefined}
      >
        {loading && <LogoSpinner color={themeColor('green-500')} height={20} style={{ position: 'absolute' }} />}
        <span>{i18n.t('modules.trix_editor.button_picture_insert')}</span>
      </label>
      <input type="file" id="image-upload" multiple={false} style={{ display: 'none' }} onChange={handleChange} />
    </>
  )
}

const ImageSelector = ({ insert = '', closeModal, thumb }: ImageSelectorProps): JSX.Element => {
  const handleClick = useCallback(() => {
    // TODO: This would break pages with multiple TrixEditor instances.
    const trixEditor = (document.getElementsByTagName('trix-editor')[0] as EditorElement).editor
    trixEditor.insertString(insert)
    trixEditor.insertLineBreak()
    trixEditor.insertLineBreak()
    closeModal()
  }, [closeModal, insert])

  return (
    <button type="button" className="trix-image-selector" onClick={handleClick}>
      <img src={thumb} alt=""></img>
      {/* As we don't have a meaningful alternative text we can only have a generic button text for screen reader users. */}
      <span className="sr-only">{i18n.t('modules.trix_editor.picture_modal_message.choose_picture')}</span>
    </button>
  )
}

const VideosButtonAndModal = (): JSX.Element => {
  const [visibleVideoModal, setVisibleVideoModal] = useState(false)
  const showVideoModal = useCallback(() => setVisibleVideoModal(true), [])
  const hideVideoModal = useCallback(() => setVisibleVideoModal(false), [])

  return (
    <>
      <button className="trix-button" type="button" onClick={showVideoModal}>
        {i18n.t('modules.trix_editor.button_video_insert')}
      </button>

      <Modal
        id="video-modal"
        onHide={hideVideoModal}
        show={visibleVideoModal}
        header={i18n.t('modules.trix_editor.video_modal_message.title')}
      >
        <I18nHtml i18nKey="modules.trix_editor.video_modal_message.explanation_html" />
      </Modal>
    </>
  )
}
