import Dropdown from 'react-bootstrap/Dropdown'
import React from 'react'
import { LoginLinkProps, MenuItemProps, User } from './types'
import { useJsonApi } from 'shared'

export const LoginLink = ({ loginCheckUrl, loginUrl }: LoginLinkProps): JSX.Element => {
  const { data } = useJsonApi(loginCheckUrl, { withCredentials: true, maxRedirects: 0 })
  const user = data as User

  if (user?.username) {
    const links = user.links.map((link, index) => <MenuItem key={index} link={link} />)

    return (
      <Dropdown alignRight id="header-login-dropdown">
        <Dropdown.Toggle as="a" className="header-link" href="#" id="header-login-dropdown-toggle">
          <img className="header-login-image" src={user.avatar_url} alt={user.name} />
          <span className="header-login-label">{i18n.t('layouts.header.my_profile')}</span>
          <i className="header-login-caret ml-2 fa fa-angle-down" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="dropdown-menu">{links}</Dropdown.Menu>
      </Dropdown>
    )
  }
  return (
    <a href={loginUrl} className="header-link">
      <span className="header-login-icon">
        <i className="fa fa-user-circle fa-fw"></i>
      </span>
      <span className="header-login-label">{i18n.t('layouts.header.login')}</span>
    </a>
  )
}

const MenuItem = ({ link }: MenuItemProps): JSX.Element => {
  if (link === 'separator') return <div role="separator" className="dropdown-divider"></div>
  return (
    <a className="dropdown-item" href={link.href}>
      {link.name}
    </a>
  )
}
