import React, { useCallback, useState } from 'react'
import classNames from 'classnames'
import styles from './styles.module.css'
import { Button } from 'shared/components/button'
import { DropzoneErrorProps, DropzoneProps, OnDropHandler } from './types'
import { FileRejection, useDropzone as useReactDropzone } from 'react-dropzone'

export const Dropzone = ({
  accept,
  multiple,
  maxSize,
  name,
  hasFormError,
  handleUpload,
  hasInlinePreviewWithFixedRatio,
  buttonOnly,
  loading,
}: DropzoneProps) => {
  const [acceptedFiles, setAcceptedFiles] = useState<Array<File>>([])
  const [rejectedFiles, setRejectedFiles] = useState<Array<FileRejection>>([])
  const [inlinePreviewUrl, setInlinePreviewUrl] = useState('')
  const showInlinePreview = hasInlinePreviewWithFixedRatio && acceptedFiles.length > 0

  const onDrop: OnDropHandler = useCallback(
    (newFiles, newRejectedFiles, event) => {
      setAcceptedFiles(newFiles)
      setRejectedFiles(newRejectedFiles)
      if (!newFiles.length || !newFiles[0]) return
      setInlinePreviewUrl(URL.createObjectURL(newFiles[0]))
      handleUpload && handleUpload(newFiles)

      // react-dropzone does not update the input on drop (only via file selection dialog) See https://github.com/react-dropzone/react-dropzone/issues/880#issuecomment-534429882
      if (inputRef.current) {
        const dragEvent = event as DragEvent
        if (dragEvent && dragEvent.dataTransfer) {
          inputRef.current.files = dragEvent.dataTransfer.files
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleUpload]
  )

  const { getRootProps, getInputProps, inputRef } = useReactDropzone({
    accept: accept,
    multiple: multiple,
    maxSize: maxSize,
    onDrop,
  })
  return (
    <>
      {buttonOnly ? (
        <div {...getRootProps()} className="mb-6">
          <input {...getInputProps()} />
          <Button type="button" loading={loading} className="btn btn-large btn-primary">
            {i18n.t('shared.dropzone.choose_files_button')}
          </Button>
        </div>
      ) : (
        <div
          {...getRootProps({
            className: classNames(
              styles.box,
              hasInlinePreviewWithFixedRatio && styles.boxFullSize,
              showInlinePreview && styles.boxWithInlinePreview,
              hasFormError && styles.boxWithFormError
            ),
          })}
        >
          <input {...getInputProps()} name={name || undefined} />
          <div
            className={classNames(
              styles.cta,
              hasInlinePreviewWithFixedRatio && styles.ctaForInlinePreview,
              showInlinePreview && 'opacity-0'
            )}
          >
            <i className={classNames('fas fa-plus-circle fa-2x', styles.icon)} />
            <span>{i18n.t('shared.dropzone.label')}</span>
          </div>
          {hasInlinePreviewWithFixedRatio &&
            acceptedFiles.map((_file, index) => (
              <div
                key={index}
                className={styles.previewImage}
                style={{
                  backgroundImage: `url(${inlinePreviewUrl})`,
                }}
              />
            ))}
        </div>
      )}
      {rejectedFiles.length > 0 && (
        <DropZoneError
          rejectedFiles={rejectedFiles}
          acceptedFiles={acceptedFiles}
          rejectionCount={rejectedFiles.length}
          maxSize={maxSize}
          accept={accept}
          multiple={multiple}
        />
      )}
    </>
  )
}

const DropZoneError = ({
  rejectedFiles,
  acceptedFiles,
  maxSize,
  accept,
  multiple,
}: DropzoneErrorProps): JSX.Element | null => {
  const firstErrorCode = rejectedFiles[0]?.errors[0]?.code.split('-').join('_')
  const acceptedTypes = accept.map((type) => type.split('/')[1]?.toUpperCase()).join(', ')
  const fileNames = rejectedFiles.map((rejection) => rejection.file.name).join(', ')

  // Don't show error if one file is allowed and there is only one accepted file
  if (!multiple && rejectedFiles.length > 0 && acceptedFiles.length === 1) return null

  // If there is only one rejected file or only one file is allowed show specific error
  if ((acceptedFiles.length < 1 && rejectedFiles.length === 1) || !multiple)
    return (
      <span className="invalid-feedback block">
        {firstErrorCode &&
          i18n.t(`shared.dropzone.${firstErrorCode}`, {
            maxFilesize: `${maxSize / 1000000} MB`,
            acceptedTypes: `${acceptedTypes}`,
          })}
      </span>
    )

  // Show general error if there are multiple rejected files
  return (
    <span className="invalid-feedback block">
      {i18n.t(`shared.dropzone.files_rejected`, {
        files: fileNames,
        maxFilesize: `${maxSize / 1000000} MB`,
        acceptedTypes: acceptedTypes,
      })}
    </span>
  )
}
