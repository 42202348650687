import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { AccordionItemCheckboxProps, AccordionItemChevronProps, AccordionItemProps } from './types'
import { JSONFetcher, SanitizationPresets, TrustedHtml, themeColor } from 'shared'

export const AccordionItem = ({ title, content, id, ...props }: AccordionItemProps) => {
  const [active, setActive] = useState(false)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const activeClass = useMemo(() => (active && 'active') || '', [active])
  const height = useMemo(() => (active ? `${contentRef.current?.scrollHeight ?? 0}px` : '0px'), [active])
  const rotate = useMemo(
    () => (active ? 'module-accordeon-item__icon rotate' : 'module-accordeon-item__icon'),
    [active]
  )
  const linkText = useMemo(() => (active ? i18n.t('misc.less') : i18n.t('misc.more')), [active])

  const toggleAccordion = useCallback(() => {
    setActive((current) => !current)
  }, [])

  useEffect(() => {
    const event = new CustomEvent('bp-accordion-loaded', { detail: { id } })
    document.dispatchEvent(event)
  }, [id])
  // !!!!! Attention! This dangerously sets inner HTML!!!!!
  // Which sadly is necessary because we render the copy-component through react in a slim template
  // and pass the resulting HTML to this component, to then render it again in a slim template. *cries*
  //
  // It is safe here because the only user provided input that gets rendered is the slugified
  // project title which should break all script injection.
  // ToDo: DEV-23401 this is fucked up and should be changed
  return (
    <div className="module-accordeon-item">
      <button className={`module-accordeon-item__container ${activeClass}`} onClick={toggleAccordion}>
        <label className="generic-custom-radio-field m-0">
          <Checkbox {...props} />
          <span className="module-accordeon-item__title">{title}</span>
        </label>
        <ChevronWithLink className={rotate} linkText={linkText} />
      </button>
      <div ref={contentRef} style={{ maxHeight: `${height}` }} className="module-accordeon-item__content">
        <TrustedHtml
          as="div"
          style={{
            borderTop: `1px solid ${themeColor('gray-500')}`,
            padding: '16px 0 16px 0',
          }}
          sanitizationPreset={SanitizationPresets.MissingPreset}
        >
          {content}
        </TrustedHtml>
      </div>
    </div>
  )
}

const ChevronWithLink = ({ direction, linkText, className, id }: AccordionItemChevronProps): JSX.Element => {
  const xPosition = direction === 'left' ? { left: -80 } : { right: -80 }

  return (
    <>
      <span className="link-text">{linkText}</span>
      <i id={id} className={classNames('hidden desktop:block fa fa-angle-down', className)} style={xPosition} />
    </>
  )
}

const Checkbox = ({ checkboxValue, checkboxUrl }: AccordionItemCheckboxProps): JSX.Element | null => {
  const [checked, setChecked] = useState(checkboxValue === 'true')

  const handleChange = useCallback(() => {
    if (!checkboxUrl) return
    JSONFetcher.put({
      url: checkboxUrl,
      success: () => {
        setChecked((checked) => !checked)
      },
    })
  }, [checkboxUrl])

  if (!checkboxUrl) return null

  return (
    <>
      <input type="checkbox" onChange={handleChange} checked={checked} />
      <span className="generic-custom-radio" style={{ display: 'inline-flex' }} />
    </>
  )
}
