/* eslint-disable import/namespace */
import history from './history'
import * as Sentry from '@sentry/react'
// TODO: On the document response level we need to add a new header in Rails: 'Document-Policy', 'js-profiling'. This is to enable the profiling feature in Sentry.
const enabled =
  process.env.NEXT_PUBLIC_ENABLE_SENTRY === '1' ||
  (!!process.env.LEGACY_SENTRY_DSN && process.env.HONEYBADGER_ENV !== 'test' && process.env.NODE_ENV === 'production')
const isNotProduction = !process.env.HONEYBADGER_ENV?.includes('production')
const debug = process.env.HONEYBADGER_ENV?.includes('staging') || process.env.NEXT_PUBLIC_ENABLE_SENTRY === '1'
Sentry.init({
  enabled,
  debug,
  environment: process.env.HONEYBADGER_ENV,
  dsn: process.env.LEGACY_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    ...(isNotProduction ? [Sentry.replayIntegration()] : []),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost:?([0-9]+)?',
    /^https?:\/\/(www|secure|api)?\.betterplace\.org/,
    /^https?:\/\/(www|secure|api)?\.betterplace\.dev/,
    /^https?:\/\/(www|secure|api)?\.bp42\.com/,
  ],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})
